import filter from 'lodash/filter'
import { calculatePaginationInfo } from 'helpers/pagination'
import * as type from '../../constants/action_types/inventory/inventory_forecast'
import { LoadingStates } from '../../constants/api'

import { getEndCount } from '../../helpers/pagination'
import {
  INVENTORY_FORECAST_COLUMNS,
  SALES_HISTORY_OPTIONS,
  INVENTORY_FORECAST_SELECTED_COLUMNS,
  FILTERS_INITIAL_STATE
} from '../../constants/inventory_forecast'

const getSelectedInventoryColumns = () => {
  const storedValue = window.localStorage.getItem(
    INVENTORY_FORECAST_SELECTED_COLUMNS
  )

  let data = storedValue ? JSON.parse(storedValue) : INVENTORY_FORECAST_COLUMNS

  if (!Array.isArray(data)) {
    data = INVENTORY_FORECAST_COLUMNS
  }

  return data.filter(e => e.name !== 'supplier_name')
}

export const initialState = {
  data: {
    createSaveState: null,
    loadingState: LoadingStates.initialLoad,
    saveState: LoadingStates.initialLoad,
    inventoryForecast: [],
    inventorySummary: {
      inventoryLevel: null,
      inventoryLevelCost: null,
      reorderCost: null,
      reorderProfit: null
    },
    inventoryColumns: getSelectedInventoryColumns(),
    pagination: {
      perPage: 20,
      startCount: 0,
      endCount: 0,
      totalCount: 0,
      currentPage: null,
      nextPage: null
    },
    search: '',
    orderDir: 'asc',
    orderBy: 'title',
    salesHistory: SALES_HISTORY_OPTIONS[1],
    showFilterModal: false,
    filters: {
      ...FILTERS_INITIAL_STATE
    },
    // Stored in an object like
    // {
    //   product_id: state
    // }
    // and
    // {
    //   product_id: [array_with_replenishment]
    // }
    replenishmentLoadingState: {},
    replenishmentData: {}
  },
  inventoryHistory: {
    loadingHistoryState: LoadingStates.initialLoad,
    data: []
  },
  ui: {
    isModalOpen: false,
    selectedProduct: null
  }
}

export default function inventoryForecastReducer(state = initialState, action) {
  let inventoryColumns
  let newColumns
  switch (action.type) {
    case type.INVENTORY_FORECAST_PER_PAGE_CHANGED:
      const { perPage } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          loadingState: LoadingStates.loaded,
          pagination: {
            ...state.data.pagination,
            perPage
          }
        }
      }
    case type.INVENTORY_FORECAST_COLUMN_TOGGLE:
      const { columnName } = action.payload
      inventoryColumns = state.data.inventoryColumns
      newColumns = inventoryColumns.map(col => {
        if (col.name === columnName) {
          col.selected = !col.selected
        }
        return col
      })

      window.localStorage.setItem(
        INVENTORY_FORECAST_SELECTED_COLUMNS,
        JSON.stringify(newColumns)
      )

      return {
        ...state,
        data: {
          ...state.data,
          inventoryColumns: newColumns
        }
      }
    case type.INVENTORY_FORECAST_ALL_COLUMNS_TOGGLE:
      inventoryColumns = state.data.inventoryColumns
      const allSelected = inventoryColumns.every(col => col.selected)
      newColumns = inventoryColumns.map(col => {
        // amazonn_image_url is excluded from all columns toggle
        if (col.name !== 'amazon_image_url') {
          col.selected = !allSelected
        }
        return col
      })

      window.localStorage.setItem(
        INVENTORY_FORECAST_SELECTED_COLUMNS,
        JSON.stringify(newColumns)
      )

      return {
        ...state,
        data: {
          ...state.data,
          inventoryColumns: newColumns
        }
      }
    case type.INVENTORY_FORECAST_LOAD_PENDING_STATUS: {
      return {
        ...state,
        data: {
          ...state.data,
          // we need to clear out summary for the loading state to make sense
          inventorySummary: {
            ...initialState.data.inventorySummary
          },
          loadingState: LoadingStates.loading
        }
      }
    }
    case type.INVENTORY_FORECAST_LOAD_COMPLETE_STATUS: {
      return {
        ...state,
        data: {
          ...state.data,
          loadingState: LoadingStates.loaded
        }
      }
    }
    case type.INVENTORY_FORECAST_UPDATE_PRODUCT_FORECAST:
      return {
        ...state,
        data: {
          ...state.data,
          inventoryForecast: state.data.inventoryForecast.map(item =>
            item.product_id === action.payload.productId
              ? { ...item, ...action.payload.data }
              : item
          )
        }
      }
    case type.INVENTORY_FORECAST_LOAD_COMPLETE:
      const {
        pagination,
        inventory_forecasts,
        inventory_forecast_summary,
        goingForwards,
        resetPagination,
        search,
        orderBy,
        orderDir
      } = action.payload

      const paginationData = calculatePaginationInfo(
        inventory_forecasts,
        state.data.pagination.nextPage,
        goingForwards,
        state.data.pagination,
        'next_page',
        resetPagination
      )

      return {
        ...state,
        data: {
          ...state.data,
          loadingState: LoadingStates.loaded,
          inventoryForecast: inventory_forecasts,
          inventorySummary: {
            inventoryLevel: inventory_forecast_summary.inventory_level,
            inventoryLevelCost: inventory_forecast_summary.inventory_level_cost,
            reorderCost: inventory_forecast_summary.reorder_cost,
            reorderProfit: inventory_forecast_summary.reorder_profit
          },
          pagination: {
            ...state.data.pagination,
            ...paginationData,
            currentPage: pagination.current_page,
            endCount: getEndCount(
              pagination.current_page,
              state.data.pagination.perPage,
              inventory_forecasts.length
            ),
            nextPage: pagination.next_page,
            totalCount: Number(pagination.total_records) || 0 // sometimes we get back "0" or similar
          },
          search,
          orderBy,
          orderDir
        }
      }
    case type.INVENTORY_FORECAST_SELECT_SALES_HISTORY: {
      return {
        ...state,
        data: {
          ...state.data,
          salesHistory: action.payload
        }
      }
    }
    case type.INVENTORY_FORECAST_SET_REPLENISHMENT_INFORMATION: {
      const { data, productId } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          replenishmentData: {
            ...state.data.replenishmentData,
            [productId]: data
          }
        }
      }
    }
    case type.INVENTORY_REPLENISHMENT_PENDING_STATUS: {
      return {
        ...state,
        data: {
          ...state.data,
          replenishmentLoadingState: {
            ...state.data.replenishmentLoadingState,
            [action.payload.productId]: LoadingStates.loading
          }
        }
      }
    }
    case type.INVENTORY_REPLENISHMENT_COMPLETE_STATUS: {
      return {
        ...state,
        data: {
          ...state.data,
          replenishmentLoadingState: {
            ...state.data.replenishmentLoadingState,
            [action.payload.productId]: LoadingStates.loaded
          }
        }
      }
    }
    case type.INVENTORY_FORECAST_HISTORICAL_TABLE_DATA_PENDING_STATUS: {
      return {
        ...state,
        inventoryHistory: {
          loadingHistoryState: LoadingStates.loading,
          data: state.inventoryHistory.data
        }
      }
    }
    case type.INVENTORY_FORECAST_HISTORICAL_TABLE_DATA_COMPLETE: {
      const inventoryHistory = state.inventoryHistory.data
      const updatedHistory = filter(inventoryHistory, item => {
        return item.productId !== action.payload.productId
      })
      return {
        ...state,
        inventoryHistory: {
          data: [...updatedHistory, action.payload]
        }
      }
    }
    case type.INVENTORY_FORECAST_HISTORICAL_TABLE_DATA_COMPLETE_STATUS: {
      return {
        ...state,
        inventoryHistory: {
          loadingHistoryState: LoadingStates.loaded,
          data: state.inventoryHistory.data
        }
      }
    }
    case type.INVENTORY_FORECAST_FILTER_MODAL_TOGGLE: {
      return {
        ...state,
        data: {
          ...state.data,
          showFilterModal: !state.data.showFilterModal
        }
      }
    }
    case type.INVENTORY_FORECAST_FILTERS_CHANGED: {
      return {
        ...state,
        data: {
          ...state.data,
          filters: {
            ...state.data.filters,
            ...action.payload.filters
          }
        }
      }
    }
    case type.INVENTORY_FORECAST_SET_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalOpen: action.payload.isOpen
        }
      }
    case type.INVENTORY_SET_SELECTED_PRODUCT_COST:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedProduct: {
            ...state.ui.selectedProduct,
            ...action.payload
          }
        }
      }
    case type.RESET_INVENTORY_FORECAST: {
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}
