import * as types from 'constants/action_types'
import { RESET_KEYWORD_LISTS } from 'constants/action_types/keywords/keyword_lists'
import {
  SET_ACTIVE_KEYWORD_LIST,
  SET_KEYWORD_LIST_VALUE
} from 'constants/action_types/keyword_lists'

export const initialState = {
  isLoading: false,
  searchTerm: '',
  keywordLists: [],
  currentModal: undefined,
  activeKeyword: {
    name: '',
    country: 'us'
  },
  keywordListCreate: {
    isLoading: false,
    completed: false
  },
  keywordListAdd: {
    isLoading: false,
    completed: false
  },

  // v2
  activeKeywordList: {}
}

const keywordListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_KEYWORD_LIST_VALUE:
      return {
        ...state,
        ...action.payload
      }
    case types.REQUEST_KEYWORD_LISTS:
      return {
        ...state,
        isLoading: true
      }
    case types.RECEIVE_KEYWORD_LISTS:
      return {
        ...state,
        isLoading: false,
        keywordLists: action.keywordLists
      }
    case types.PENDING_KEYWORD_LIST_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case types.COMPLETED_KEYWORD_LIST_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: true
        }
      }
    case types.RESET_KEYWORD_LIST_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: false
        }
      }
    case types.KEYWORD_LIST_ITEM_ATTRIBUTE_CHANGE:
      return {
        ...state,
        activeKeyword: {
          ...state.activeKeyword,
          [action.payload.prop]: action.payload.value
        }
      }
    case types.RESET_KEYWORD_LIST_ITEM:
      return {
        ...state,
        activeKeyword: { ...initialState.activeKeyword }
      }
    case RESET_KEYWORD_LISTS:
      return {
        ...initialState
      }
    case SET_ACTIVE_KEYWORD_LIST:
      return {
        ...state,
        activeKeywordList: action.keywordList
      }
    default:
      return state
  }
}

export default keywordListReducer
