import * as type from 'constants/action_types/market_insights/segment_builder'

export const initialState = {
  active: true,
  id: null,
  name: '',
  country: 'us',
  autoRefresh: false,
  searchId: null,
  search: {
    keywords: null,
    brands: null,
    asins: null,
    categories: null,
    terms: null
  },
  filters: {
    brands: [],
    excludeBrands: [],
    categories: [],
    excludeCategories: [],
    excludeTitleKeywords: [],
    excludeAsins: [],
    price: { min: null, max: null },
    monthlyRevenue: { min: null, max: null },
    reviews: { min: null, max: null },
    excludeUnavailableProducts: false
  },
  summary: {
    numParentCategories: 0,
    numSubcategories: 0,
    numBrands: 0,
    numAsins: 0
  },
  table: {
    products: [],
    sort: {
      column: 'estRevenue',
      direction: 'desc'
    },
    page: {
      size: 200,
      from: 0
    },
    totalPages: 0
  },
  segmentSearch: {
    isLoading: false,
    completed: false
  },
  saveSegment: {
    isLoading: false,
    errors: [],
    validAsinsParameterLimit: true,
    validBrandsParameterLimit: true,
    validKeywordsParameterLimit: true,
    validExcludeKeywordsLimit: true,
    validExcludeAsinsLimit: true
  }
}

function segmentBuilder(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_SEGMENT_BUILDER_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true,
          completed: false
        }
      }
    case type.RESET_SEGMENT_BUILDER_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: false
        }
      }
    case type.COMPLETED_SEGMENT_BUILDER_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: true
        }
      }
    case type.SET_SEGMENT_BUILDER_DATA:
      return {
        ...state,
        ...action.payload
      }
    case type.RESET_SEGMENT_BUILDER_DATA:
      return {
        ...initialState
      }
    case type.SET_ASINS_PARAMETER_LIMIT:
      return {
        ...state,
        saveSegment: {
          ...state.saveSegment,
          validAsinsParameterLimit: action.payload
        }
      }
    case type.SET_BRANDS_PARAMETER_LIMIT:
      return {
        ...state,
        saveSegment: {
          ...state.saveSegment,
          validBrandsParameterLimit: action.payload
        }
      }
    case type.SET_KEYWORDS_PARAMETER_LIMIT:
      return {
        ...state,
        saveSegment: {
          ...state.saveSegment,
          validKeywordsParameterLimit: action.payload
        }
      }
    case type.SET_EXCLUDE_ASINS_FILTER_LIMIT:
      return {
        ...state,
        saveSegment: {
          ...state.saveSegment,
          validExcludeAsinsLimit: action.payload
        }
      }
    case type.SET_EXCLUDE_KEYWORDS_FILTER_LIMIT:
      return {
        ...state,
        saveSegment: {
          ...state.saveSegment,
          validExcludeKeywordsLimit: action.payload
        }
      }
    default:
      return state
  }
}
export default segmentBuilder
