import * as types from '../constants/action_types/keyword_products'

export const initialState = {
  /* Popuated by get_products API call, with `county/keywords` making up the key
  e.g.
  [
    "us/cat food":
    {
      asin: "B07R7S8LSH",
      listingQualityScore: "42",
      etc...
    },
    etc..
  ]  
  */
}

function keywordProducts(state = initialState, action) {
  switch (action.type) {
    case types.PENDING_KEYWORD_PRODUCTS_ACTION: {
      const existingState = state[action.payload.id] || {}
      return {
        ...state,
        [action.payload.id]: {
          ...existingState,
          isLoading: true,
          sort: {
            direction: 'asc'
          }
        }
      }
    }
    case types.RESET_KEYWORD_PRODUCTS_ACTION: {
      const existingState = state[action.payload.id] || {}
      return {
        ...state,
        [action.payload.id]: {
          ...existingState,
          isLoading: false
        }
      }
    }
    case types.SET_KEYWORD_PRODUCTS: {
      const existingState = state[action.payload.id] || {}
      return {
        ...state,
        [action.payload.id]: {
          ...existingState,
          products: action.payload.products
        }
      }
    }
    case types.SORT_KEYWORD_PRODUCTS: {
      const existingState = state[action.payload.id] || {}
      return {
        ...state,
        [action.payload.id]: {
          ...existingState,
          products: action.payload.products,
          sort: action.payload.sort
        }
      }
    }
    default:
      return state
  }
}

export default keywordProducts
