import { combineReducers } from 'redux'

import categories from './categories'
import segments from './segments'
import shareAndTrends from './share_and_trends'
import segmentBuilder from './segment_builder'

export default combineReducers({
  categories,
  segments,
  shareAndTrends,
  segmentBuilder
})
