import { DEFAULT_OPPORTUNITY_FINDER_FILTERS } from 'constants/filters'
import { getShowState } from 'helpers/core'
import * as type from 'constants/action_types'

export const initialState = {
  isLoading: false,
  hasSearchBeenDone: false,
  data: {
    total_count: 0,
    total_pages: 1,
    selectedPage: 1,
    keywords: [],
    selectAllState: {},
    currencyCode: 'USD',
    selectedCountry: 'us'
  },

  filters: {
    ...DEFAULT_OPPORTUNITY_FINDER_FILTERS
  },

  presets: {
    isLoading: false,
    name: '',
    userPresets: [],
    jsPresets: [],
    selectedPreset: {}
  },

  showPresetSave: false,
  showPresetLoad: false,
  showPresetDelete: false,
  excludeTopBrands: true
}

function keywordReducer(state = initialState, action) {
  switch (action.type) {
    case type.FIRST_OPPORTUNITY_SEARCH:
      return {
        ...state,
        hasSearchBeenDone: action.hasSearchBeenDone
      }
    case type.PENDING_KEYWORD_SEARCH:
      return {
        ...state,
        isLoading: true
      }
    case type.FAILED_KEYWORD_SEARCH:
      return {
        ...state,
        isLoading: false
      }
    case type.COMPLETED_KEYWORD_SEARCH: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        },
        isLoading: false
      }
    }
    case type.SORT_KEYWORDS:
      return {
        ...state,
        filters: {
          ...state.filters,
          sort: {
            ...state.filters.sort,
            column: action.column,
            direction: action.direction
          }
        }
      }
    case type.SET_KEYWORD_COUNTRY:
      return {
        ...state,
        data: {
          ...state.data,
          currencyCode: action.payload.currencyCode,
          selectedCountry: action.payload.country
        },
        filters: {
          ...state.filters,
          country: {
            ...state.filters.country,
            valuesArray: [action.payload.country]
          },
          category: {
            ...state.filters.category,
            valuesArray: initialState.filters.category.valuesArray
          }
        }
      }
    case type.SET_KEYWORD_CATEGORIES:
      return {
        ...state,
        data: {
          ...state.data,
          selectAllState: {
            ...state.data.selectAllState,
            [state.data.selectedCountry]: action.selectAllState
          }
        },
        filters: {
          ...state.filters,
          category: {
            ...state.filters.category,
            valuesArray: action.categories
          }
        }
      }
    case type.SET_KEYWORD_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: {
            ...state.filters[action.name],
            [action.property]: action.value
          }
        }
      }
    case type.SET_KEYWORD_MIN_MAX_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: {
            ...state.filters[action.name],
            min: action.min,
            max: action.max
          }
        }
      }
    case type.SET_KEYWORD_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          selectedPage: action.page
        },
        filters: {
          ...state.filters,
          paginate: {
            ...state.filters.paginate,
            from: action.from
          }
        }
      }
    case type.RESET_KEYWORD_FILTERS:
      return {
        ...initialState,
        data: {
          ...initialState.data,
          currencyCode: state.data.currencyCode,
          selectedCountry: state.data.selectedCountry
        },
        filters: {
          ...initialState.filters,
          country: state.filters.country
        }
      }
    case type.TOGGLE_KEYWORD_SAVE_PRESET: {
      const newShowState = getShowState(state.showPresetSave, action.show)
      return {
        ...state,
        showPresetSave: newShowState
      }
    }
    case type.TOGGLE_KEYWORD_LOAD_PRESET: {
      const newShowState = getShowState(state.showPresetLoad, action.show)
      return {
        ...state,
        showPresetLoad: newShowState
      }
    }
    case type.TOGGLE_KEYWORD_DELETE_PRESET: {
      const newShowState = getShowState(state.showPresetDelete, action.show)
      return {
        ...state,
        showPresetDelete: newShowState,
        showPresetLoad: !newShowState
      }
    }
    case type.TOGGLE_EXCLUDE_TOP_BRANDS: {
      return {
        ...state,
        excludeTopBrands: !state.excludeTopBrands
      }
    }
    case type.UPDATE_KEYWORD_PRESET_NAME:
      return {
        ...state,
        presets: {
          ...state.presets,
          name: action.value
        }
      }
    case type.TOGGLE_KEYWORD_MULTIUSER_SHARING: {
      return {
        ...state,
        presets: {
          ...state.presets,
          is_private: action.value
        }
      }
    }
    case type.SELECT_KEYWORD_PRESET:
      return {
        ...state,
        presets: {
          ...state.presets,
          selectedPreset: action.value
        }
      }
    case type.PENDING_KEYWORD_PRESET:
      return {
        ...state,
        presets: {
          ...state.presets,
          isLoading: action.isLoading
        }
      }
    case type.SET_KEYWORD_PRESETS:
      return {
        ...state,
        presets: {
          ...state.presets,
          userPresets: action.userPresets,
          jsPresets: action.jsPresets
        }
      }
    case type.LOAD_KEYWORD_PRESET: {
      const selectedCountry = action.filters.country
        ? action.filters.country.valuesArray[0]
        : state.data.selectedCountry

      return {
        ...state,
        data: {
          ...state.data,
          selectedCountry
        },
        filters: {
          ...initialState.filters,
          ...action.filters
        }
      }
    }
    default:
      return state
  }
}

export default keywordReducer
