import * as types from '../../constants/action_types/market_insights/categories'

export const initialState = {
  fetch: {
    isLoading: false
  },
  search: {
    searchTerm: '',
    data: null
  },
  categories: [],
  currentPageCategories: [],
  tree: [],
  selectedCategories: {},
  selectAllCategories: false
}

function categories(state = initialState, action) {
  switch (action.type) {
    case types.PENDING_MARKET_INSIGHT_CATEGORY_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case types.RESET_MARKET_INSIGHT_CATEGORY_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false
        }
      }
    case types.SET_MARKET_INSIGHT_CATEGORIES_DATA:
      return {
        ...state,
        ...action.payload
      }
    case types.RESET_NEW_MARKET_INSIGHT_CATEGORY:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default categories
