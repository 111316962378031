import * as types from '../../constants/action_types/market_insights/segments'

export const initialState = {
  segments: null,
  segment: {
    is_private: false
  },
  filters: {
    name: '',
    page: 0,
    sort: 'name',
    direction: 'asc'
  },
  segmentsFetch: {
    isLoading: false
  },
  saveSegment: {
    isLoading: false
  },
  deleteSegment: {
    isLoading: false
  },
  deleteBulkSegments: {
    isLoading: false
  },
  updateBulkSegments: {
    isLoading: false,
    active: false
  },
  totalAsins: 0,
  segmentDetailsFetch: {
    isLoading: false
  },
  segmentSummaryFetch: {
    isLoading: false
  },
  selectedCategoryNames: {},
  segmentBuilder: {
    unsavedChanges: false,
    noResults: false,
    filtersChanged: true,
    total_product_pages: null,
    products: []
  },
  newSegment: {
    name: '',
    static: true,
    all_categories: false,
    filters: {
      country: {
        type: 'overrideTerms',
        valuesArray: ['us']
      },
      sort: {
        type: 'sort',
        column: 'estRevenue',
        direction: 'desc'
      },
      paginate: {
        type: 'paginate',
        pageSize: 200,
        from: 0
      },
      price: {
        type: 'range',
        min: null,
        max: null
      },
      estRevenue: {
        type: 'range',
        min: null,
        max: null
      },
      nReviews: {
        type: 'range',
        min: null,
        max: null
      },
      rating: {
        type: 'range',
        min: null,
        max: null
      },
      'asin.sortable': {
        type: 'includeOverrideQuery',
        searchTerm: '',
        queryFields: ['asin']
      },
      asinsExcludeQuery: {
        type: 'eQuery',
        searchTerm: '',
        queryFields: ['asin']
      },
      brandsQuery: {
        type: 'terms',
        field: 'brand.sortable',
        valuesArray: []
      },
      brandsExcludeQuery: {
        type: 'termsExclude',
        field: 'brand.sortable',
        valuesArray: []
      },
      keywordsQuery: {
        type: 'query',
        searchTerm: '',
        queryFields: ['name']
      },
      keywordsExcludeQuery: {
        type: 'eQuery',
        searchTerm: '',
        queryFields: ['name']
      },
      subCategories: {
        nestedField: 'id',
        type: 'nestedTerms',
        valuesArray: []
      },
      isUnavailable: {
        type: 'terms',
        valuesArray: []
      },
      state: {
        type: 'terms',
        valuesArray: ['active']
      },
      searchTerms: {
        type: 'terms',
        valuesArray: []
      }
    },
    total_asins: null,
    total_revenue: null,
    total_brands: null,
    average_price: null,
    average_reviews: null,
    monthly_unit_sales: null
  },
  totalPages: 0,
  totalCount: 0,
  deleteASINNotificationDisplayed: false,
  deleteBrandNotificationDisplayed: false,
  selectedSegmentIds: []
}

function segments(state = initialState, action) {
  switch (action.type) {
    case types.PENDING_SEGMENT_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case types.SET_BULK_UPDATE_SEGMENTS_VALUES:
      return {
        ...state,
        updateBulkSegments: {
          ...state.updateBulkSegments,
          ...action.payload
        }
      }
    case types.RESET_SEGMENT_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false
        }
      }
    case types.SET_SEGMENTS_DATA:
      return {
        ...state,
        ...action.payload
      }
    case types.UPDATE_SEGMENTS_DATA:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          ...action.payload
        }
      }
    case types.SET_SEGMENT_FILTER:
      return {
        ...state,
        newSegment: {
          ...state.newSegment,
          filters: {
            ...state.newSegment.filters,
            [action.name]: {
              ...state.newSegment.filters[action.name],
              [action.property]: action.value
            }
          },
          filtersChanged: true
        }
      }
    case types.RESET_SEGMENT_BUILDER:
      return {
        ...state,
        segmentBuilder: {
          ...initialState.segmentBuilder
        }
      }
    case types.PENDING_CATEGORY_NAMES_ACTION:
      return {
        ...state,
        selectedCategoryNames: {
          ...state.selectedCategoryNames,
          [action.payload.id]: {
            ...state.selectedCategoryNames[action.payload.id],
            isLoading: true
          }
        }
      }
    case types.RESET_CATEGORY_NAMES_ACTION:
      return {
        ...state,
        selectedCategoryNames: {
          ...state.selectedCategoryNames,
          [action.payload.id]: {
            ...state.selectedCategoryNames[action.payload.id],
            isLoading: false
          }
        }
      }
    case types.SET_CATEGORY_NAMES_DATA:
      return {
        ...state,
        selectedCategoryNames: {
          ...state.selectedCategoryNames,
          [action.payload.id]: {
            ...state.selectedCategoryNames[action.payload.id],
            names: action.payload.names
          }
        }
      }
    case types.SET_SELECTED_SEGMENTS:
      return {
        ...state,
        selectedSegmentIds: action.payload
      }
    default:
      return state
  }
}

export default segments
