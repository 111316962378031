import * as types from 'constants/action_types/market_insights/share_and_trends'
import moment from 'moment'
import { computePreviousPeriodRange } from 'helpers/market_insights/share_and_trends'
import { createDateRanges } from 'helpers/calendar'
import { SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/market_insights/share_and_trends'

const defaultDateRange = {
  selectedRangeKey: '1_YEARS',
  startDate: moment().subtract(1, 'years'),
  endDate: moment()
}
const baseDate = moment()
  .utc()
  .subtract(1, 'day')
  .endOf('day')

const dateRanges = createDateRanges(
  baseDate,
  SHARE_AND_TRENDS_DATA_ATTRIBUTE_IDS
)
const { endDate, rangeType, startDate } = dateRanges[9]
const { previousStartDate, previousEndDate } = computePreviousPeriodRange(
  startDate,
  endDate,
  'previousPeriod',
  rangeType
)

export const initialState = {
  segments: null,
  segment: {},
  segmentsFetch: {
    isLoading: false
  },
  segmentTrends: [],
  getSegmentTrends: {
    isLoading: false,
    errorLoading: false
  },
  brandTrends: {
    asins: [],
    avgReviews: [],
    avgPrice: [],
    unitSales: [],
    revenue: [],
    revenue1p: [],
    revenue3p: []
  },
  brandTrendsTreeMap: [],
  getBrandTrendsTreeMap: {
    isLoading: false,
    errorLoading: false
  },
  getBrandTrends: {
    isLoading: false,
    errorLoading: false
  },
  segmentChartLines: ['unitSales', 'revenue'],
  brandTrendsType: 'revenue',
  brandChartsType: 'line',
  segmentBrands: {
    brands: [],
    isLoading: false
  },
  getSegmentBrands: {
    isLoading: false,
    errorLoading: false
  },
  segmentBrandProducts: {},
  segmentBrandProductVariants: {},
  selectedBrandsForDropdown: [],
  allBrandsForDropdown: [],
  brandsForDropdown: {
    brands: []
  },
  getBrandsForDropdown: {
    isLoading: false,
    errorLoading: false
  },
  currentBrandPage: 1,
  totalBrandPages: 1,
  brandsFilter: {
    ...defaultDateRange,
    sort: 'revenue',
    direction: 'desc'
  },
  segmentChartInterval: 'weekly',
  brandChartInterval: 'weekly',
  brandTrendsDateRange: defaultDateRange,
  segmentTrendsDateRange: defaultDateRange,
  shareAndTrendsPreviousDateRange: {
    previousStartDate,
    previousEndDate
  },
  usingAdminMegaQueries: true,
  segmentTrendsRangeType: '1 Year'
}

function shareAndTrends(state = initialState, action) {
  switch (action.type) {
    case types.PENDING_SHARE_AND_TRENDS_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case types.RESET_SHARE_AND_TRENDS_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false
        }
      }
    case types.SET_SHARE_AND_TRENDS_DATA:
      return {
        ...state,
        ...action.payload
      }
    case types.PENDING_BRAND_PRODUCTS_ACTION:
      return {
        ...state,
        segmentBrandProducts: {
          ...state.segmentBrandProducts,
          [action.payload.brand]: {
            ...state.segmentBrandProducts[action.payload.brand],
            [action.payload.section]: {
              ...state.segmentBrandProducts[action.payload.brand]?.[
                action.payload.section
              ],
              isLoading: true
            }
          }
        }
      }
    case types.RESET_BRAND_PRODUCTS_ACTION:
      return {
        ...state,
        segmentBrandProducts: {
          ...state.segmentBrandProducts,
          [action.payload.brand]: {
            ...state.segmentBrandProducts[action.payload.brand],
            [action.payload.section]: {
              ...state.segmentBrandProducts[action.payload.brand]?.[
                action.payload.section
              ],
              isLoading: false
            }
          }
        }
      }
    case types.SET_BRAND_PRODUCTS_DATA:
      return {
        ...state,
        segmentBrands: {
          ...state.segmentBrands,
          brands: action?.payload?.brands
            ? action?.payload?.brands
            : state.segmentBrands.brands
        },
        segmentBrandProducts: {
          ...state.segmentBrandProducts,
          [action.payload.brand]: {
            ...state.segmentBrandProducts[action.payload.brand],
            [action.payload.section]: {
              ...state.segmentBrandProducts[action.payload.brand]?.[
                action.payload.section
              ],
              ...action.payload.data
            }
          }
        }
      }
    case types.PENDING_BRAND_PRODUCT_VARIANTS_ACTION:
      return {
        ...state,
        segmentBrandProductVariants: {
          ...state.segmentBrandProductVariants,
          [action.payload.asin]: {
            ...state.segmentBrandProductVariants[action.payload.asin],
            isLoading: true
          }
        }
      }
    case types.RESET_BRAND_PRODUCT_VARIANTS_ACTION:
      return {
        ...state,
        segmentBrandProductVariants: {
          ...state.segmentBrandProductVariants,
          [action.payload.asin]: {
            ...state.segmentBrandProductVariants[action.payload.asin],
            isLoading: false
          }
        }
      }
    case types.SET_SHARE_AND_TRENDS_PREVIOUS_PERIOD_DATES:
      return {
        ...state,
        shareAndTrendsPreviousDateRange: action.payload
      }
    case types.SET_BRAND_PRODUCT_VARIANTS_DATA:
      return {
        ...state,
        segmentBrands: {
          ...state.segmentBrands,
          brands: action?.payload?.segmentBrands?.brands
            ? action?.payload?.segmentBrands.brands
            : state.segmentBrands.brands
        },
        segmentBrandProductVariants: {
          ...state.segmentBrandProductVariants,
          [action.payload.asin]: {
            ...state.segmentBrandProductVariants[action.payload.asin],
            ...action.payload.data
          }
        }
      }
    case types.SET_SHARE_AND_TRENDS_RANGE_TYPE:
      return {
        ...state,
        segmentTrendsRangeType: action.payload
      }
    case types.SET_SHARE_AND_TRENDS_SEGMENTS:
      return {
        ...state,
        segments: action.payload
      }
    default:
      return state
  }
}

export default shareAndTrends
